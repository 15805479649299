<template>
  <div>
    <v-card>
      <v-card-title>
        {{ $t("Gestion de stock") }}
      </v-card-title>
      <v-row class="px-2 ma-0">
        <v-col
          cols="12"
          sm="6"
        >
          <v-text-field
            v-model="search"
            :placeholder="$t('Rechercher par le nom')"
            :label="$t('Rechercher par le nom')"
            outlined
            dense
            hide-default-footer
            hide-details
          />
        </v-col>
      </v-row>
      <v-data-table
        v-model="selected"
        :loading-text="$t('Chargement...')"
        :headers="headers"
        :items="items"
        :option.sync="pagination"
        :server-items-length="total"
        item-key="id"
        dense
        fixed-header
        height="500"
        :loading="loading"
        :footer-props="{ showFirstLastPage, showCurrentPage }"
      >
        <template v-slot:item.actions="{ item }">
          <v-btn
            color="primary"
            dark
            small
            class="mb-1"
            sm="2"
            @click="editItem(item)"
          >
            {{ $t('approvisionnement') }}
          </v-btn>
        </template>
        <!--        <template v-slot:footer>-->
        <!--          <v-row-->
        <!--            class="my-5 me-1"-->
        <!--            align="center"-->
        <!--            justify="center"-->
        <!--          >-->
        <!--            <v-spacer />-->

        <!--            <span-->
        <!--              class="mr-4-->
        <!--                                grey&#45;&#45;text"-->
        <!--            >-->
        <!--              {{ $t("Page") }} {{ page }} {{ $t("de") }} {{ total }}-->
        <!--          </span>-->
        <!--            <v-btn-->
        <!--              :loading="loading"-->
        <!--              fab-->
        <!--              :disabled="page == 1"-->
        <!--              small-->
        <!--              color="primary"-->
        <!--              class="mr-1"-->

        <!--              @click="formerPage"-->
        <!--            >-->
        <!--              <v-icon>mdi-chevron-left</v-icon>-->
        <!--            </v-btn>-->
        <!--            <v-btn-->
        <!--              :loading="loading"-->
        <!--              fab-->
        <!--              :disabled="page == Pages"-->
        <!--              small-->
        <!--              color="primary"-->
        <!--              class="ml-1"-->
        <!--              @click="nextPage"-->
        <!--            >-->
        <!--              <v-icon>mdi-chevron-right</v-icon>-->
        <!--            </v-btn>-->
        <!--          </v-row>-->
        <!--        </template>-->
      </v-data-table>
      <v-dialog
        v-model="dialog"
        max-width="500px"
      >
        <v-card>
          <v-card-title>
            <span class="text-5">{{ $t('Ajouter un stock') }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                >
                  <v-text-field
                    v-model="item.name"
                    type="text"
                    disabled
                    :label="$t('nom')"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                >
                  <v-text-field
                    v-model="item.qty"
                    type="number"
                    :label="$t('valeur')"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="blue darken-1"
              text
              @click="close"
            >
              {{ $t('Annuler') }}
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="save"
            >
              {{ $t('Enregistrer') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
  </div>
</template>
<script>

  export default {
    data () {
      return {
        isSidebar: false,
        filter: {
          search: '',
        },
        page: 0,
        rowsPerPage: 10,
        pagination: { rowsPerPage: 10, page: 1, sortDesc: [true] },
        item: {},
        showFirstLastPage: true,
        showCurrentPage: true,
        items: [],
        disabled: true,
        selected: [],
        total: 0,
        dialog: false,
        loading: false,
        timeout: null,
        range: [0, 100, 500, 1000],
        itemsTwo: ['Low to High', 'High to Low', 'Date'],
        search: '',
        confirm_dialog: false,
        loadingDelete: false,
        snackbar: { active: false, text: '', color: 'info' },
        headers: [
          { text: 'NOM', value: 'name' },
          { text: 'STOCK PHYSIQUE', value: 'quantity' },
          { text: 'STOCK RESERVE', value: 'stock_reserved' },
          { text: 'STOCK DISPONIBLE', value: 'stock_available' },
          { text: 'ACTIONS', value: 'actions' },
        ],
      }
    },

    computed: {
      pages () {
        if (this.pagination.rowsPerPage == null ||
          this.pagination.totalItems == null
        ) return 0

        return Math.ceil(this.pagination.totalItems / this.pagination.rowsPerPage)
      },
      params () {
        return {
          ...this.pagination,
          page: this.pagination.page,
          query: this.search,
        }
      },
    },

    watch: {
      pagination: {
        handler () {
          console.log('test')

          if (this.timeout) {
            clearTimeout(this.timeout)
          }
          this.timeout = setTimeout(() => {
            this.getData().then(data => {
              this.items = data.items
              this.total = data.total
              this.loading = false
            })
          }, 1500)
        },
        deep: true,
      },
      params: {
        handler () {
          console.log('test')

          if (this.timeout) {
            clearTimeout(this.timeout)
          }
          this.timeout = setTimeout(() => {
            this.getData().then(data => {
              this.items = data.items
              this.total = data.total
              this.loading = false
            })
          }, 1500)
        },
        deep: true,
      },
      dialog (val) {
        val || this.close()
      },
    },
    mounted () {
      this.getData().then(data => {
        this.items = data.items
        this.total = data.total

      // if (this.page + 1 <= this.Pages) this.page += 1
      })
    },

    methods: {
      nextPage () {
        if (this.page + 1 <= this.Pages) {
          this.getData().then(data => {
            for (var i = 0; i < data.items.length; i++) {
              var index = this.items.findIndex(e => e.id === data.items[i].id)
              if (index === -1) {
                this.items.push(data.items[i])
              } else {
                this.items[index] = data.items[i]
              }
            }
            // this.items.push(...data.items)

            this.total = data.total
            if (this.page + 1 <= this.Pages) this.page += 1
          })
        }
      },
      formerPage () {
        if (this.page - 1 >= 1) this.page -= 1
      },
      season (val) {
        return this.range[val]
      },
      getData () {
        this.loading = true
        return new Promise((resolve, reject) => {
          console.log(this.pagination)
          axios.get('/products', {
            params: {
              term: this.filter.search,
              orderBy: this.pagination.sortBy ? this.pagination.sortBy[0] : 'id',
              direction: (this.pagination.sortDesc) ? (this.pagination.sortDesc[0] ? 'DESC' : 'ASC') : 'DESC',
              page: this.page + 1,
              per_page: this.pagination.rowsPerPage,
            },
          })
            .then(res => {
              const items = res.data.data
              const total = res.data.total
              this.loading = false
              resolve({
                items,
                total,
              })
            })
            .catch(err => console.log(err))
            // eslint-disable-next-line no-return-assign
            .finally(() => this.loading = false)
        })
      },
      editItem (item) {
        this.item = item
        this.dialog = true
      },
      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },
      save () {
        console.log('save')
        this.loading = true
        return new Promise((resolve, reject) => {
          axios({
            method: 'post',
            url: '/stock/update/' + this.item.id,
            data: { id: this.item.id, qty: this.item.qty },
            // eslint-disable-next-line no-unused-vars
          })
            .catch(error => {
              this.loading = false
              // TODO: Next Update - Show notification
              console.error('Oops, Unable to submit!')
              console.log('error :>> ', error.response || '')
              // this.errorMessages = error.response.data.error
            }).then(response => {
              const items = response.data.data
              const total = response.data.total
              this.loading = false
              resolve({
                items,
                total,
              })
            })
          this.close()
        })
      },
    },
  }

</script>
